
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';

import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ListHeader from '../../_common/list-days-header.vue';
import HotelsList from '../../_common/hotels-list/index.vue';
import MarketsDaysSet from './markets-day-set.vue';

@Component({
    components: {
        ListHeader,
        HotelsList,
    },
})
export default class MarketsClusterList extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterMarketsServiceS) private clusterMarketsService!: ClusterMarketsService;
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    isFirstLoading = true;
    contentHeight: string | null = null;

    get tableData() {
        if (this.hotels === null || !this.hotels.length) {
            return [];
        }

        return this.hotels.map(h => ({
            table: [
                {
                    value: h.hotelName,
                    onClick: () => this.toHotel(h),
                },
                {
                    value: h.avgPosition,
                },
            ],
            component: {
                element: MarketsDaysSet,
                props: { hotelData: h },
            },
        }));
    }

    get headerData() {
        return [
            {
                value: 'hotelName',
                name: 'Hotel name',
            },
            {
                value: 'avgPosition',
                name: 'Average position',
            },
        ];
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get hotels() {
        return this.clusterMarketsService.hotels;
    }

    get pagesCount() {
        return this.clusterMarketsService.hotelsCount;
    }

    sort() {
        this.clusterMarketsService.toggleScoreSort();
    }

    private async toHotel(hotelData: ClusterHotelsMarketsModel) {
        this.documentFiltersService.compsetIdFromHighLevel = null;
        this.documentFiltersService.compsetId = null;
        this.marketsService.storeState.providersMarketsDocuments = {};
        this.documentFiltersService.savePos(null);

        const mainCompset = this.clusterService.getMainCompset(hotelData);

        if (mainCompset) {
            this.documentFiltersService.settings.compsetId = mainCompset.id;
            this.documentFiltersService.settings.pos = mainCompset.mainPos || mainCompset.pos[0];
        }

        await this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(hotelData.hotelId),
            },
        });
    }
}
