

import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import ChainService, { ChainServiceS } from '@/modules/chain/chain.service';
import MarketsClusterOtelMixin from '@/modules/open-telemetry/mixins/markets/markets-cluster-otel.mixin';
import MarketsHeader from '../components/markets/markets-cluster-header.vue';
import MarketsActions from '../components/markets/markets-cluster-actions.vue';
import MarketsList from '../components/markets/list/markets-cluster-list.vue';

@Component({
    components: {
        PageWrapper,
        MarketsHeader,
        MarketsActions,
        MarketsList,
    },
})
export default class MarketClusterPage extends Mixins(MarketsClusterOtelMixin) {
    @Inject(ChainServiceS) private chainService!: ChainService;

    beforeMount() {
        this.chainService.resetChainPair();

        // NOTE: Here will chain logic (CI-3824) (#chain)

        this.clusterService.hotels = null;
    }

    destroyed() {
        this.clusterService.resetLoading();
    }
}
