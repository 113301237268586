
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import type Day from '@/modules/common/types/day.type';

export interface AssessmentData {
    isPastDay: boolean;
    isNA: boolean;
    colorModificator: string;
}

@Component
export default class CalendarChain extends Vue {
    @Inject(DocumentFiltersServiceS)
    protected documentFiltersService!: DocumentFiltersService;

    @Inject(EventsManagerServiceS)
    protected eventsManagerService!: EventsManagerService;

    @Prop({ type: Boolean })
    public skeleton!: boolean;

    @Prop({ type: Array })
    private assessments!: AssessmentData[];

    @Prop({ type: Boolean })
    public disableEvents!: boolean;

    get calculatedAssestments() {
        return this.assessments || Array(30).fill({});
    }

    haveHolidayEvents(day: Day) {
        const { year, month } = this.documentFiltersService;
        const date = new Date(year, month, day);
        return this.eventsManagerService
            .hasDateHolidayEvents(date);
    }

    haveLocalEvents(day: Day) {
        const { year, month } = this.documentFiltersService;
        const date = new Date(year, month, day);
        return this.eventsManagerService
            .hasDateOtherEvents(date);
    }

    cellOut() {
        this.$emit('cellout');
    }

    checkCell(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellhover', e.target);
        }
    }

    cellClick(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellclick', e.target);
        }
    }
}
