var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapper',{staticClass:"markets-cluster",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('MarketsHeader')]},proxy:true},{key:"main",fn:function(){return [_c('MarketsActions'),_c('MarketsList',{staticClass:"markets-cluster__hotel-list"})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"markets-cluster-footer"},[_c('div',[_vm._v(" Position comparing to competitors: ")]),_c('div',{staticClass:"calendar-legend"},[_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--dark-green',
                ]},[_vm._v(" High ")]),_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--green',
                ]},[_vm._v(" Med-High ")]),_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--yellow',
                ]},[_vm._v(" Med-Low ")]),_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--red',
                ]},[_vm._v(" Poor ")]),_c('div',{class:[
                    'calendar-legend__cell',
                ]},[_vm._v(" No Data ")])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }