
import { Component, Vue } from 'vue-property-decorator';
import Pagination from '@/modules/common/components/ui-kit/pagination.vue';
import { debounce } from 'lodash';
import { Inject } from 'inversify-props';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterService, { ClusterServiceS } from '../../cluster.service';

@Component({ components: { Pagination, CustomSelect } })
export default class ClusterPagination extends Vue {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    beforeMount() {
        this.clusterService.skip = 0;
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get pagesCount() {
        return this.clusterService.pagesCount;
    }

    get paginationPage() {
        return this.clusterService.skip + 1;
    }

    set paginationPage(page: number) {
        this.clusterService.skip = page - 1;
    }

    get limit() {
        return this.clusterService.limit;
    }

    set limit(n: number) {
        this.clusterService.limit = n;
        // Reset pagination page on limit change
        this.clusterService.skip = 0;
    }

    get items() {
        return [10, 20, 30, 40, 50].map(v => ({
            name: v,
            value: v,
        }));
    }
}
