var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',{staticClass:"markets-hint",scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" Visibility Indication ")]},proxy:true},{key:"hoverContent",fn:function(){return [_c('i',{staticClass:"icon-Icons_Qusetion-mark markets-hint__icon"})]},proxy:true}])},[_c('div',[_vm._v(" Shows your hotel position vs. competitors ")]),_c('div',{staticClass:"markets-hint__indicators-bar"},[_c('div',{staticClass:"good-dark"},[_vm._v("High")]),_c('div',{staticClass:"good"},[_vm._v("Med-High")]),_c('div',{staticClass:"fair"},[_vm._v("Med-Low")]),_c('div',{staticClass:"poor"},[_vm._v("Poor")])]),_c('div',{class:[
        'markets-hint__indicator',
        'markets-hint__indicator--no-data'
    ]},[_c('div',[_vm._v(" No Data ")]),_vm._v(" No Visibility data was found ")]),_c('div',{class:[
            'markets-hint__indicator',
            'markets-hint__indicator--na',
        ]},[_c('div',[_vm._v(" N/A ")]),_vm._v(" Visibility for the hotel is not found in the first 10 pages ")]),_c('div',{class:[
            'markets-hint__indicator',
        ]},[_c('div',[_vm._v("Out Of Range")]),_vm._v(" Dates are out of range ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }