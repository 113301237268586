
import { Prop, Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';

import Flag from '@/modules/common/components/ui-kit/flag.vue';
import Day from '@/modules/common/types/day.type';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import { ASSESSMENT_TYPES, COLOR_NAMES } from '@/modules/common/constants';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import PAGES from '@/modules/common/constants/pages.constant';
import MarketsCalendarLine from './markets-calendar-line.vue';
import DayTooltip from '../../_common/day-tooltip.vue';

const DEFAULT_TOOLTIP_DATA = {
    pos: null,
    color: COLOR_NAMES.GRAY,
    date: null,
    position: null,
    page: null,
    dayState: null,
};

@Component({
    components: {
        MarketsCalendarLine,
        DayTooltip,
        Flag,
    },
})
export default class MarketsDaySet extends Vue {
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(ClusterMarketsServiceS) private clusterMarketsService!: ClusterMarketsService;

    @Inject(MarketsCommonServiceS)
    private marketsCommonService!: MarketsCommonService;

    @Prop({ type: Object, default: () => ({}) })
    hotelData!: ClusterHotelsMarketsModel;

    tooltipData: {
        pos: HTMLElement | null;
        color: COLOR_NAMES;
        date: Date | null;
        position: number | null;
        page: number | null;
        dayState: string | null;
    } = DEFAULT_TOOLTIP_DATA;

    isCalendarLoading = false;

    get mainCompset() {
        if (this.skeleton) return null;
        return this.clusterService.getMainCompset(this.hotelData);
    }

    get skeleton() {
        return !this.hotelData.hotelName;
    }

    get haveOtherEvents() {
        if (!this.tooltipData.date) return false;
        const { date } = this.tooltipData;

        return this.eventsManagerService.hasDateOtherEvents(date);
    }

    get flagList() {
        if (!this.tooltipData.date) return [];

        const { date } = this.tooltipData;
        const events = this.eventsManagerService.getHolidayEvents(date);

        return Array.from(
            new Set(
                events.map(e => e.countryCode),
            ),
        );
    }

    get isOutOfRange() {
        return this.marketsCommonService
            .isOutOfRange(this.hotelData.compsetMain);
    }

    get flagsMoreBy() {
        return this.flagList.length - 3;
    }

    get isLoading() {
        return this.isCalendarLoading || !this.hotelData.documentLoaded;
    }

    get formatedDate() {
        if (!this.tooltipData.date) return '';
        return moment(this.tooltipData.date).format('ddd, MMM DD, YYYY');
    }

    // Lazy load of document, send request only if component is in viewport.
    loadViewportedHotel(isVisible: boolean) {
        if (this.isCalendarLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isCalendarLoading = true;
        this.clusterService
            .loadDaysData(PAGES.MARKETS, this.hotelData.hotelId)
            .finally(() => {
                this.isCalendarLoading = false;
            });
    }

    setActiveDay(el: HTMLDivElement) {
        const { year, month } = this.documentFiltersService;
        const { hotelId, compsetMain } = this.hotelData;

        const day = +el.dataset.day! as Day;
        const assessment = +el.dataset.type! as ASSESSMENT_TYPES;

        const modificators: { [k: number]: COLOR_NAMES } = {
            [ASSESSMENT_TYPES.GOOD]: COLOR_NAMES.DARK_GREEN,
            [ASSESSMENT_TYPES.NORMAL]: COLOR_NAMES.GREEN,
            [ASSESSMENT_TYPES.FAIR]: COLOR_NAMES.YELLOW,
            [ASSESSMENT_TYPES.BAD]: COLOR_NAMES.RED,
            [ASSESSMENT_TYPES.NO_DATA]: COLOR_NAMES.WHITE,
            [!this.isNa(day) ? ASSESSMENT_TYPES.SOLD_OUT : -1]: COLOR_NAMES.GRAY,
            [this.isNa(day) ? ASSESSMENT_TYPES.SOLD_OUT : -1]: COLOR_NAMES.RED,
        };

        let dayState = null;

        if (this.isOutOfRange) {
            dayState = 'Out Of Range';
        } else if (this.clusterMarketsService.isNA(day, hotelId)) {
            dayState = 'N/A';
        } else if (this.clusterMarketsService.isNoData(day, hotelId)) {
            dayState = 'No Data';
        }

        const position = compsetMain
            ? this.clusterMarketsService.getPosition(day, hotelId, compsetMain)
            : null;

        const page = compsetMain
            ? this.clusterMarketsService.getPage(day, hotelId, compsetMain)
            : null;

        const newTooltipData = {
            pos: el,
            color: modificators[assessment],
            date: new Date(year, month, day),
            position,
            page,
            dayState,
        };

        this.tooltipData = newTooltipData;
    }

    hideTooltip() {
        this.tooltipData = DEFAULT_TOOLTIP_DATA;
    }

    openPopup(el: HTMLElement) {
        if (!this.mainCompset) return;

        const day = +el.dataset.day! as Day;

        this.$router.push({
            name: `${this.$route.name!}.day-markets`,
            params: {
                day: String(day),
                hotelId: String(this.hotelData.hotelId),
                compsetId: this.mainCompset.id,
                source: this.clusterService.currentMarketsProvider,
            },
        });
    }

    private isNa(day: Day) {
        return this.clusterMarketsService
            .isNA(day, this.hotelData.hotelId);
    }
}
